@charset "UTF-8";
.section.intro {
  @include respond(sp) {
    @include max('margin-bottom', 40);
    padding-top: $header-height;

  }
  @include respond(tab-port) {
    margin-bottom: 6rem;
    padding-top: $header-height-pc;

  }
  .section {
    &__nav-item p {
      @include respond(tab-port) {
        @include mv("margin-left", 35);
      }
    }
    &__breadcrumb {
      @include respond(sp) {
        @include max("margin-top", 10);
        
      }
      @include respond(tab-port) {
        margin-top: 6rem;
      }
    }
  }
}
