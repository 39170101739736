@charset "UTF-8";

$md-radio-checked-color: $color-primary;
$md-radio-border-color: $color-primary;
$md-radio-ripple-size: 15px;

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  // border-radius: 0;
  /* autoprefixer: ignore next */
  -webkit-box-sizing: content-box;
  /* autoprefixer: ignore next */
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}


// section override
.section.footer {
  @include respond(sp) {
    padding-bottom: 0;
  }
}
// form wrapper
.section.contact {
  @include respond(sp) {
    @include max('margin-bottom', 50);
  }
  @include respond(tab-port) {
    margin-bottom: 3rem;
    &.confirm,
    &.error{
      margin-bottom: 0;
    }
  }

  & .section {
    &__text {
      color: $color-primary-light;
      padding: 0;
      @include respond(sp) {
        @include max("margin-bottom", 30);
      }
      @include respond(tab-port) {
        margin-bottom: 3rem;
      }
    }
  }
}

//  form content
.mailform_block,
.confirm_block {
  width: 100%;
  font-family: $font-noto;

  @include respond(sp) {
    &:not(:last-child) {
      @include max("margin-bottom", 30);
    }

  }

  @include respond(tab-port) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mv("gap", 60);
    &:not(:last-child) {
      margin-bottom: 6rem;
    }
    &:nth-of-type(3) {
      margin-bottom: 3rem;
    }
    &:last-child .mailform_block--label {
      margin-top: 8px;
      margin-bottom: auto;
    }
  }
  &__form {
  }
  &__form-wrapper {
    background-color: $color-white;
    @include respond(sp) {
      @include max("margin-bottom", 30);
      @include max("padding", 30);
    }
    @include respond(tab-port) {
      margin-bottom: 6rem;
      padding: 6rem 9rem 6rem 8rem;
    }
  }

  &--label {
    @include respond(sp) {
      @include max("margin-bottom", 10);
      @include max("font-size", 16);
      letter-spacing: 0.03em;
      line-height: 1.875;
      & span {
        display: block;
      }
    }
    @include respond(tab-port) {
      width: 27.5rem;
      font-size: 1.6rem;
      letter-spacing: 0;
      line-height: 1.5;
    }
  }
  &--input {
    @include respond(sp) {
    }
    @include respond(tab-port) {
      width: 100%;
    }
    &-radio {
      display: flex;
      flex-direction: column;
      @include respond(sp) {
        @include max("font-size", 14);
        letter-spacing: 0;
        line-height: 1.428;
        & .input-text {
          @include max("padding-left", 26);
        }
      }
      & label {
        display: inline-block;
        position: relative;
        cursor: pointer;
        vertical-align: bottom;
        &:not(:last-child) {
          @include respond(sp) {
            @include max("margin-bottom", 15);
          }
        }
      }
      @include respond(tab-port) {
        margin-top: 0;
        font-size: 1.6rem;
        line-height: 1.875;
        letter-spacing: 0.03em;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        @include mv('gap', 30);
        & .input-text {
          width: max-content;
        }
      }
    }
    &-text,
    &-mail {
      // confirm画面用
      @include respond(sp) {
        @include max("font-size", 13);
        letter-spacing: 0.05em;
        line-height: 1.538;
      }
      // 入力画面用
      & input {
        width: 100%;
        padding: 1rem 1.6rem;
        @include respond(sp) {
          @include max("height", 40);
          @include max("font-size", 13);
          @include mp("padding", 11, 20, 10, 20);
          letter-spacing: 0.05em;
          line-height: 1.538;
        }
        @include respond(tab-port) {
          font-size: 1.4rem;
          line-height: 1.428;
          letter-spacing: 0;
          padding: 2rem 3rem;
          max-width: 40rem;
          height: 6rem;
        }
      }
    }
    &-textarea {
      // confirm画面用
      @include respond(sp) {
        @include max("font-size", 13);
        letter-spacing: 0.05em;
        line-height: 1.538;
      }
      // 入力画面用
      & textarea {
        width: 100%;
        @include respond(sp) {
          @include max("height", 200);
          @include mp("padding", 11, 20, 10, 20);
        }
        @include respond(tab-port) {
          height: 30rem;
          padding: 2rem 3rem;
        }
      }
    }
  }

  &__button {
    background-color: $color-primary;
    color: $color-menu-white;
    display: block;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease;
    outline: none;
    @include respond(sp) {
      @include max("border-radius", 10);
      @include max("font-size", 18);
      @include max("padding-top", 17);
      @include max("padding-bottom", 17);
      letter-spacing: 0.07em;
      line-height: 1.777;
    }
    @include respond(tab-port) {
      border-radius: 1rem;
      font-size: 1.8rem;
      line-height: 1.777;
      letter-spacing: 0.07em;
      width: 40rem;
      height: 9rem;
      margin-right: auto;
      margin-left: auto;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    @include respond(hover) {
      &:hover {
        opacity: 0.7;
      }
      &.disabled:hover {
        opacity: 0.4;
      }
    }
  }
  &__error {
    color: $color-red;
    text-align: center;
    @include respond(sp) {
      @include max('font-size', 13);
      line-height: 1.5384;
      letter-spacing: 0;
      @include max('margin-top', 20);
    }
    @include respond(tab-port) {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 1.6666;
      letter-spacing: 0.05em;
    }

    &.hide {
      display: none;
    }
  }
}

.mailform_block--label {
  &-validation {
    position: relative;
    &::after {
      content: "※必須";
      color: $color-primary;
      @include respond(sp) {
        position: absolute;
        @include max("font-size", 12);
        letter-spacing: 0;
        line-height: 1.416;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      @include respond(tab-port) {
        position: relative;
        font-size: 1.4rem;
        line-height: 1.428;
        letter-spacing: 0;
        right: -1rem;
      }
    }
  }
}
.confirm_block {
  @include respond(tab-port) {
    align-items: flex-start;
  }
  & .mailform_block--label {
    &-validation {
      position: relative;
      &::after {
        content: "";
        display: none;
      }
    }
  }
  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-right: auto;
    margin-left: auto;
    transition: 0.5s ease;
    @include respond(sp) {
      @include max('margin-top', 30);
      @include max("font-size", 13);
      letter-spacing: 0.05em;
      line-height: 1.538;
      & .arrow {
        @include max('margin-right', 10);
        align-self: auto;
      }
    }
    @include respond(tab-port) {
      margin-top: 3rem;
      font-size: 1.8rem;
      letter-spacing: 0.07em;
      line-height: 1.777;
      & .arrow {
        margin-right: 1rem;
        align-self: auto;
      }
    }
    @include respond(hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}


.section.intro.complete {
  & .confirm_block__back {
    margin-left: 0;
  }
}

.section.intro.not-found {
  & .section__wrapper {
    @include respond(sp) {
      @include max('padding-top', 30);
    }
    @include respond(tab-port) {
      padding-top: 6rem;
    }
  }
  & .confirm_block__back {
    margin-left: 0;
    @include respond(sp) {
      @include max('margin-top', 30);
    }
    @include respond(tab-port) {
      margin-top: 6rem;
    }
  }
}