
// radio buttton
@keyframes radio-select {
  0% {
    transform: translate(-50%, -50%) scale(0, 0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

label {
  display: block;
  position: relative;
  cursor: pointer;
  @include respond(sp) {
    // @include max('padding-left', 20);
  }
  @include respond(tab-port) {
  }
  &.image {
    & .outside {
      @include respond(sp) {
        top: 92%;
      }
      @include respond(tab-port) {
        top: 92%;
      }
    }
  }
  & .input-text {
    display: inline-block;
    @include respond(sp) {
      @include max("padding-left", 16);
      // letter-spacing: -0.07em;
    }
    @include respond(tab-port) {
      padding-left: 2rem;
    }
  }
}

input[type="radio"] {
  display: inline-block;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.outside {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid $color-black;
  border-radius: 50%;
  box-sizing: border-box;
  background: $color-menu-white;
  @include respond(sp) {
    @include max("width", 15);
    @include max("height", 15);
    @media only screen and (max-width: 320px) {
      @include max("width", 16);
      @include max("height", 16);
    }
  }
  @include respond(tab-port) {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.inside {
  @include centerer;
  display: inline-block;
  border-radius: 50%;
  background: $color-red;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  @include respond(sp) {
    @include max("width", 7);
    @include max("height", 7);
    @media only screen and (max-width: 320px) {
      @include max("width", 8);
      @include max("height", 8);
    }
  }
  @include respond(tab-port) {
    width: 0.7rem;
    height: 0.7rem;
  }
}

input {
  &:checked {
    + .outside .inside {
      animation: radio-select 0.1s linear;
      transform: translate(-50%, -50%) scale(1, 1);
    }
  }
}
